<template>
  <div class="video-js-wrapper">
    <video-player
      class="vjs-big-play-centered"
      ref="videoPlayer"
      :options="playerOptions"
      @play="onPlayerPlay($event)"
      :playsinline="true"
    >
    </video-player>
  </div>
</template>

<script>
import 'video.js/dist/video-js.min.css';
import 'video.js';
import 'videojs-flash';
import 'videojs-contrib-hls';
import { videoPlayer } from 'vue-video-player';

export default {
  name: 'VideoJS',
  props: {
    parsedSources: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    videoPlayer,
  },
  data() {
    return {
      playerOptions: {
        muted: true,
        autoplay: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        aspectRatio: '16:9',
        controlBar: false,
      },
    };
  },
  methods: {
    onPlayerPlay() {
      this.$emit('hideLogo');
    },
  },
  created() {
    this.playerOptions.sources = this.parsedSources;
  },
};
</script>

<style scoped lang="scss">
  .video-js {
    width: 100%;
    max-width: 100%;
  }
</style>
